<template>
  <div>
    <img
      class="hidden-sm-and-down"
      style="width: 100%"
      src="../../assets/mock/scene/solution1.jpg"
      alt=""
    />
    <img
      class="hidden-md-and-up"
      style="width: 100%"
      src=""
      alt=""
    />
    <div class="warp">
      <div class="top">
        <h3>全部概览</h3>
        <span></span>
        <p>宇起智能指纹锁为家庭提供了全套智能锁解决方案， 我们家庭用的智能指纹门锁、衣柜、抽屉柜、客厅电视柜、床头柜等等所有木质柜都可以用上我们宇起的智能指纹锁，回家手指轻轻一点，开启所有的锁，告别钥匙时代。</p>
      </div>
      <div class="top">
        <h3>智能家居指纹锁应用场景</h3>
        <span></span>
        <img style="width: 100%;" src="../../assets/mock/scene/solution1.png" alt="">
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
 .top {
    text-align: center;
    margin: 3.125rem 0;
    h3 {
      margin-top: 1.25rem;
    }
    span {
      width: 1.25rem;
      height: 3px;
      display: inline-block;
      background-color: #3cb59e;
    }
  }
</style>